import $ from 'jquery';
import TomSelect from 'tom-select/dist/js/tom-select.complete.min.js';
let fieldsAreInitialized = false;

function initSelects() {
  const selects = document.querySelectorAll(
    'select.custom-select, .gform_wrapper select'
  );

  if (0 < selects.length) {
    selects.forEach((select) => {
      new TomSelect(select, {
        create: false,
      });
    });
  }
}

function changeFileUpload() {
  const multiFileUploads = document.querySelectorAll(
    '.gform_fileupload_multifile'
  );
  if (0 < multiFileUploads.length) {
    multiFileUploads.forEach(function (fileUpload) {
      fileUpload.querySelector('.gform_drop_instructions').textContent =
        wp.i18n._x(
          'Drag and drop file to upload',
          'drag and drop instruction',
          'vtx'
        );
    });
  }
}

function initNumberRanges(formID) {
  const form = document.querySelector('form[data-formid="' + formID + '"');
  const numberRanges = form.querySelectorAll(
    '.gfield--type-number.number-range'
  );

  if (0 < numberRanges.length) {
    numberRanges.forEach((numberRange) => {
      const container = numberRange.querySelector('.ginput_container_number');
      const numbInput = numberRange.querySelector('input[type="number"]');
      const rangeWrapper = document.createElement('input');
      const numbMinus = document.createElement('button');
      const numbPlus = document.createElement('button');

      if (!numbInput.value) {
        numbInput.setAttribute('value', 0);
      }

      numbInput.setAttribute('min', 0);
      numbInput.setAttribute('max', 500);
      numbMinus.setAttribute('type', 'action');
      numbMinus.setAttribute('class', 'minus');
      numbMinus.innerHTML = '-';
      numbPlus.setAttribute('type', 'action');
      numbPlus.setAttribute('class', 'plus');
      numbPlus.innerHTML = '+';
      rangeWrapper.setAttribute('type', 'range');
      rangeWrapper.setAttribute('value', numbInput.value);
      rangeWrapper.setAttribute('min', 0);
      rangeWrapper.setAttribute('max', 500);
      rangeWrapper.style.setProperty('--min', 0);
      rangeWrapper.style.setProperty('--value', numbInput.value);
      rangeWrapper.style.setProperty('--max', 500);
      rangeWrapper.classList.add('range-slider');
      rangeWrapper.classList.add('slider-progress');

      container.appendChild(numbMinus);
      container.appendChild(numbPlus);
      numberRange.appendChild(rangeWrapper);

      if (500 === parseInt(numbInput.value)) {
        numbPlus.setAttribute('disabled', true);
      } else if (
        500 > parseInt(numbInput.value) &&
        numbPlus.getAttribute('disabled')
      ) {
        numbPlus.removeAttribute('disabled');
      }

      if (0 === parseInt(numbInput.value)) {
        numbMinus.setAttribute('disabled', true);
      } else if (
        0 < parseInt(numbInput.value) &&
        numbMinus.getAttribute('disabled')
      ) {
        numbMinus.removeAttribute('disabled');
      }

      container.classList.add('range-initialized');

      rangeWrapper.addEventListener('change', function () {
        rangeWrapper.style.setProperty('--value', rangeWrapper.value);
        numbInput.value = rangeWrapper.value;
        numbInput.classList.add('value-updated');

        if (500 === parseInt(rangeWrapper.value)) {
          numbPlus.setAttribute('disabled', true);
        } else if (
          500 > parseInt(rangeWrapper.value) &&
          numbPlus.getAttribute('disabled')
        ) {
          numbPlus.removeAttribute('disabled');
        }

        if (0 === parseInt(rangeWrapper.value)) {
          numbMinus.setAttribute('disabled', true);
        } else if (
          0 < parseInt(rangeWrapper.value) &&
          numbMinus.getAttribute('disabled')
        ) {
          numbMinus.removeAttribute('disabled');
        }

        setTimeout(function () {
          numbInput.classList.remove('value-updated');
        }, 500);
      });

      numbInput.addEventListener('change', function () {
        rangeWrapper.style.setProperty('--value', numbInput.value);
        rangeWrapper.value = numbInput.value;

        if (500 === parseInt(numbInput.value)) {
          numbPlus.setAttribute('disabled', true);
        } else if (
          500 > parseInt(numbInput.value) &&
          numbPlus.getAttribute('disabled')
        ) {
          numbPlus.removeAttribute('disabled');
        }

        if (0 === parseInt(numbInput.value)) {
          numbMinus.setAttribute('disabled', true);
        } else if (
          0 < parseInt(numbInput.value) &&
          numbMinus.getAttribute('disabled')
        ) {
          numbMinus.removeAttribute('disabled');
        }
      });

      numbMinus.addEventListener('click', function (e) {
        e.preventDefault();
        if (0 < numbInput.value) {
          numbInput.value--;
          rangeWrapper.style.setProperty('--value', numbInput.value);
          rangeWrapper.value = numbInput.value;

          if (500 === parseInt(rangeWrapper.value)) {
            numbPlus.setAttribute('disabled', true);
          } else if (
            500 > parseInt(rangeWrapper.value) &&
            numbPlus.getAttribute('disabled')
          ) {
            numbPlus.removeAttribute('disabled');
          }

          if (0 === parseInt(rangeWrapper.value)) {
            numbMinus.setAttribute('disabled', true);
          } else if (
            0 < parseInt(rangeWrapper.value) &&
            numbMinus.getAttribute('disabled')
          ) {
            numbMinus.removeAttribute('disabled');
          }
        }
      });

      numbPlus.addEventListener('click', function (e) {
        e.preventDefault();
        if (500 > numbInput.value) {
          numbInput.value++;
          rangeWrapper.style.setProperty('--value', numbInput.value);
          rangeWrapper.value = numbInput.value;

          if (500 === parseInt(rangeWrapper.value)) {
            numbPlus.setAttribute('disabled', true);
          } else if (
            500 > parseInt(rangeWrapper.value) &&
            numbPlus.getAttribute('disabled')
          ) {
            numbPlus.removeAttribute('disabled');
          }

          if (0 === parseInt(rangeWrapper.value)) {
            numbMinus.setAttribute('disabled', true);
          } else if (
            0 < parseInt(rangeWrapper.value) &&
            numbMinus.getAttribute('disabled')
          ) {
            numbMinus.removeAttribute('disabled');
          }
        }
      });
    });
  }
}

function changeFormLabel(label, input) {
  if ('' !== input.value) {
    label.classList.add('active');
  }

  label.addEventListener('click', function () {
    input.focus();
  });

  input.addEventListener('focus', function () {
    label.classList.add('active');
  });

  input.addEventListener('focusout', function () {
    if (!input.value || '(___) ___-____' === input.value) {
      label.classList.remove('active');
    }
  });
}

function addFormEvents() {
  const fields = document.querySelectorAll('.gfield');

  if (0 < fields.length) {
    fields.forEach(function (field) {
      if (field.classList.contains('initialized')) {
        return;
      }

      const classesExcluded = [
        'gfield--radio',
        'gfield--checkbox',
        'gfield--consent',
        'gfield--select',
        'number-range',
      ];

      for (let i = 0; i < classesExcluded.length; i++) {
        if (field.classList.contains(classesExcluded[i])) {
          return;
        }
      }

      field.classList.add('initialized');

      const label = field.querySelector('.gfield_label');
      const input = field.querySelector(
        '.ginput_container input , .ginput_container textarea'
      );

      if (label && input) {
        changeFormLabel(label, input);
      }

      if (field.classList.contains('gfield--has-multiple-inputs')) {
        const subfields = field.querySelectorAll('.ginput_complex > span');

        if (0 < subfields.length) {
          subfields.forEach(function (subfield) {
            if (subfield.classList.contains('initialized')) {
              return;
            }
            subfield.classList.add('initialized');

            const sublabel = subfield.querySelector('label');
            const subinput = subfield.querySelector('input, textarea');
            changeFormLabel(sublabel, subinput);
          });
        }
      }
    });
  }
}

$(document).on('gform_post_render', function (e, formID) {
  fieldsAreInitialized = true;
  changeFileUpload();
  addFormEvents();
  initSelects();
  initNumberRanges(formID);
});

// ON READY
window.addEventListener('load', function () {
  if (!fieldsAreInitialized) {
    changeFileUpload();
    addFormEvents();
    initSelects();
    initNumberRanges();
  }
});
