import domReady from '@wordpress/dom-ready';
import $ from 'jquery';

function initTransitionWords(btns) {
  if (0 < btns.length) {
    btns.forEach((btn) => {
      const btnText = btn.textContent;
      const btnWords = btnText.split(' ');
      const textWrapper = document.createElement('span');
      textWrapper.classList.add('transition-word-wrapper');

      if (0 < btnWords.length) {
        btnWords.forEach((word, index) => {
          const wordHTML = document.createElement('span');
          wordHTML.classList.add('word');
          const wordChars = word.split('');

          if (0 < wordChars.length) {
            wordChars.forEach((char) => {
              const charHTML = document.createElement('span');
              const initialState = document.createElement('span');
              const hoverState = document.createElement('span');
              charHTML.classList.add('word__char');
              initialState.classList.add('word__char__initial-state');
              hoverState.classList.add('word__char__hover-state');
              initialState.textContent = char;
              hoverState.textContent = char;
              charHTML.appendChild(initialState);
              charHTML.appendChild(hoverState);
              wordHTML.appendChild(charHTML);
            });
          }

          if (index !== btnWords.length - 1) {
            const spaceHTML = document.createElement('span');
            spaceHTML.classList.add('word__char');
            spaceHTML.classList.add('word__char--space');
            spaceHTML.textContent = ' ';
            wordHTML.appendChild(spaceHTML);
          }

          textWrapper.appendChild(wordHTML);
        });
      }

      btn.textContent = '';
      btn.appendChild(textWrapper);
    });
  }
}

domReady(() => {
  const siteBtns = document.querySelectorAll(
    '.btn, .btn--outline, .wp-element-button'
  );
  initTransitionWords(siteBtns);
});

$(document).on('gform_post_render', function (e, formID) {
  const form = document.querySelector('form[data-formid="' + formID + '"');
  const formBtns = form.querySelectorAll('.gravity-btn');
  initTransitionWords(formBtns);
});
